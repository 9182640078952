<template>
    
    <v-dialog v-model="dialog" :persistent="formLoading" content-class="br-16" scrollable max-width="500px">
        <v-card>
          <v-card-title>
            <h3>{{ $t('edit_provider') }}</h3>
            <v-spacer></v-spacer>
            <div v-if="!isAgent">
              <h5>{{ $t('all_provider_games') }}: {{ provider.total_games }}</h5>
              <div class="d-flex" >
                <span class="c-green">{{ $t('active_provider_games') }}: {{ provider.active_games }} </span>
                <v-spacer></v-spacer>
                <span class="c-red">{{ $t('not_active_provider_games') }}: {{ provider.inactive_games }}</span>
              </div>
            </div>
  
          </v-card-title>
          <v-card-text class="pb-0 py-2">
            <!-- Name -->
            <v-text-field outlined dense color="var(--main-flamingo)" disabled hide-details class="mb-3"
              v-model="provider.name" :label="this.$t('provider')"></v-text-field>
            <v-text-field outlined dense color="var(--main-flamingo)" disabled hide-details class="mb-3"
              v-model="provider.aggregator" :label="this.$t('aggregator')"></v-text-field>
            <!-- Quantity Games -->
            <!-- Change min / max bet -->
            <div class="bonus-block__header" v-if="!isAgent">
              <v-checkbox color="var(--main-flamingo)" class="mt-0 mb-2" hide-details 
          v-model="isAllCurrencies"
      :label="$t('select_all_currencies')"></v-checkbox>
          <v-select
          hide-details="auto"
            :rules="[v => selectedCurrencies.length > 0 || $t('required_field')]"
            multiple
            class="auto"
            :disabled="isAllCurrencies"
            :menu-props="{contentClass: 'main-list'}"
            v-model="selectedCurrencies"
            :items="filteredCurrencies"
            :label="$t('min_bet_currency')"
            outlined
            dense
            color="var(--main-flamingo)"
            >
          </v-select>
        </div>
        <div class="d-flex align-center flex-wrap">
          <template v-for="(limits, currency) in provider.min_bet">
            <v-text-field hide-details="auto" 
            :key="currency" 
            v-if="selectedCurrencies.includes(currency)"
                v-model="provider.min_bet[currency]" 
                outlined
                :rules="[v => v > 0 || $t('bet_greater_than_zero')]" 
                required 
                dense 
                class="auto"
                color="var(--main-flamingo)"
                :label="$t('min_bet')" 
                :suffix="currency">
              </v-text-field>
        </template>
          </div>
            <!-- Change currency -->
            <div class="bonus-block__header" v-if="!isAgent">
              <v-checkbox color="var(--main-flamingo)" class="mt-0 mb-2" hide-details 
          v-model="isAllAllowedCurrencies"
      :label="$t('all_currencies')"></v-checkbox>
          <v-select
          hide-details="auto"
            :rules="[v => selectedAllowedCurrencies.length > 0 || $t('required_field')]"
            multiple
            class="auto"
            :disabled="isAllAllowedCurrencies"
            :menu-props="{contentClass: 'main-list'}"
            v-model="selectedAllowedCurrencies"
            :items="filteredCurrencies"
            :label="$t('allowed_currencies')"
            outlined
            dense
            color="var(--main-flamingo)"
            >
          </v-select>
        </div>
  
            <!-- Change Photo -->
            <FileUpload v-if="!provider.logo && !isAgent" name="provider-logo[]" :multiple="false" :customUpload="true" @uploader="changeProviderFile" accept="image/*" :maxFileSize="1000000">
              <template #empty>
                <span>{{ $t('game_logo_select')
                }}</span>
                <p>{{ $t('drag_logo')
                }}</p>
              </template>
          </FileUpload>
            <v-col style="font-size: 12px;" class="load-photo load-photo_provider" v-if="provider.logo">
              <div class="d-flex flex-column align-center justify-center" v-if="provider.logo">
                <img :src="!logoPreview ? provider.logo : logoPreview"
                  style="object-fit: contain;width:220px;height:220px">
                <!-- Delete Photo -->
                <v-btn v-if="!isAgent" color="var(--red)" max-width="10" max-height="30" class="mt-1" @click="deleteImage">
                  <v-icon class="icon__delete-t" style="color: var(--white);">close</v-icon>
                </v-btn>
              </div>
            </v-col>
            <!-- Limit Photo Exceed -->
            <v-row class="mt-1" v-if="providerExceed" style="color: var(--red)">
              <v-col>
                {{ $i18n.t('game_logo_exceed') }}
              </v-col>
            </v-row>
            <!-- Input Photo -->
            <input type="file" ref="imagePreview" accept="image/png, image/jpeg, image/svg+xml" style="display: none"
              id="change-provider-input" @change="changeProviderFile($event)">
            <!-- Change active -->
           <div class="d-flex flex-column space-y-1 mt-3">
             <!-- Allowed to agents -->
          <span class="form-switch"> 
            <h4 class="c-text white-space title-input">
              {{ isAgent ? $t('active') : $t('allowed_to_agents') }}:
            </h4>
            <div class="wrapper-input d-flex">
              <InputSwitch class="p-inputswitch-success" v-model="provider.allowed_to_agents" />
            </div>
        </span>
            <!-- Active -->
          <span v-if='!isAgent' class="form-switch"> 
            <h4 class="c-text white-space title-input">
              {{ $t('active') }}:
            </h4>
            <div class="wrapper-input d-flex">
              <InputSwitch :disabled="isAgent" class="p-inputswitch-success" v-model="provider.is_enabled" />
            </div>
        </span>
       
           </div>
          </v-card-text>
          <!-- Close and Save -->
          <v-card-actions>
            <v-spacer></v-spacer>
              <Button class="p-button-sm p-button-danger mr-1" @click="closeProviderDialog" 
              :loading="formLoading"
              :disabled="formLoading" :label="$t('close')"></Button>
              <Button class="p-button-sm p-button-success" @click="updateManager(provider)" 
              :loading="formLoading"
              :disabled="provider.logo === null || formLoading" :label="$t('save')"></Button>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
    export default {
        name: 'EditProvider',
        props:{
          providerDialog: Boolean,
          provider: Object,
        },
        data() {
            return {
                providerExceed: false,
                logoPreview: null,
                formLoading: false,
                dialog: false,
                selectedCurrencies: [],
                selectedAllowedCurrencies: [],
                isAllCurrencies: false,
                isAllAllowedCurrencies: false,
            }
        },
        computed: {
          ...mapGetters({
            filteredCurrencies: 'getFilteredCurrencies',
            tokenUser: 'tokenUser',
          }),
          isAgent(){
            return this.tokenUser === 'agent';
          },
        },
        watch:{
          provider: {
    handler(newProv, oldBonus) {
      // editBonus
      // selectedCurrencies
      if (this.provider && this.provider.min_bet) {
      
      var keys = Object.keys(newProv.min_bet);
      if (keys.length === 0) {
        return
      }
      this.selectedCurrencies = [];
      Object.keys(newProv.min_bet).forEach(currency => {
          this.selectedCurrencies.push(currency);
        });
      }
      if (newProv && Array.isArray(newProv.allowed_currencies)) {
          //  'ALL'
          if (newProv.allowed_currencies.includes('ALL')) {
            this.isAllAllowedCurrencies = true;
            return;
          } else{
            this.selectedAllowedCurrencies = [];
  
            newProv.allowed_currencies.forEach(currency => {
              console.log('currency', currency);
              this.selectedAllowedCurrencies.push(currency);
            });
          }

        }
    },
    deep: true 
  },
  //========================================================================================================================================================
  
  selectedCurrencies(newCurrencies) {
      if (!this.provider.min_bet) {
        this.$set(this.provider, 'min_bet', {});
      }
      for (const currency in this.provider.min_bet) {
        if (!newCurrencies.includes(currency)) {
          delete this.provider.min_bet[currency];
        }
      }
      newCurrencies.forEach(currency => {
        if (!this.provider.min_bet[currency]) {
          this.$set(this.provider.min_bet, currency, '');
        }
      });
    },
  isAllCurrencies(newValue) {
            if (newValue) {
                // add currencies
                const currencies = Object.keys(this.filteredCurrencies).map(currency => {
                    return this.filteredCurrencies[currency];
                });
                this.selectedCurrencies = currencies;
            } else {
                // to delete from selectedCurrencies
                this.selectedCurrencies = [];
            }
        },
        //========================================================================================================================================================
        
    //     selectedAllowedCurrencies(newCurrencies) {
    //   if (!this.provider.allowed_currencies) {
    //     this.$set(this.provider, 'allowed_currencies', {});
    //   }
    //   for (const currency in this.provider.allowed_currencies) {
    //     if (!newCurrencies.includes(currency)) {
    //       delete this.provider.allowed_currencies[currency];
    //     }
    //   }
    //   newCurrencies.forEach(currency => {
    //     if (!this.provider.allowed_currencies[currency]) {
    //       this.$set(this.provider.allowed_currencies, currency, '');
    //     }
    //   });
    // },
    isAllAllowedCurrencies(newValue) {
            if (newValue) {
                // add currencies
                const currencies = Object.keys(this.filteredCurrencies).map(currency => {
                    return this.filteredCurrencies[currency];
                });
                this.selectedAllowedCurrencies = currencies;
            } else {
                // to delete from selectedCurrencies
                this.selectedAllowedCurrencies = [];
            }
        },
        //========================================================================================================================================================
        
          providerDialog: {
            immediate: true,
            handler(newValue) {
              if (newValue) {
          this.dialog = newValue;    
              }
            }
          },
          dialog: {
            handler(newValue) {
              if (newValue === false) {
                this.$emit('provider-close');
                this.isAllAllowedCurrencies = false;
                this.selectedAllowedCurrencies = [];
                this.deleteImage();
              }
            }
          },
        },
        methods: {
          deleteImage() {
      URL.revokeObjectURL(this.logoPreview);
      this.provider.logo = null;
      this.logoPreview = null;
    },
          closeProviderDialog(){
            this.$emit('provider-close');
            this.dialog = false;
          },
            async updateManager(provider) {
      this.formLoading = true;
      let endpoint;
      this.isAgent ? endpoint = `/api/providers/by_agent/${provider.id}` :
        endpoint = `/api/providers/${provider.id}`;
        const requestData = new FormData();
        requestData.append("allowed_to_agents", provider.allowed_to_agents || false);
        requestData.append("order", provider.order || 0);
        if (!this.isAgent) {
        requestData.append("is_enabled", provider.is_enabled || false);
        requestData.append("name", provider.name || '');
        requestData.append("fee", provider.fee || 0);
      if (provider.logo !== null && typeof provider.logo !== 'string') {
        requestData.append("logo", provider.logo);
      } else if (provider.logo.includes("https")) {
        requestData.append("logo", provider.logo);
      }
      if (provider.min_bet) {
          const minBet = Object.keys(provider.min_bet)
          if (minBet.length) {
            for (const currency in provider.min_bet) {
              provider.min_bet[currency] = Number(provider.min_bet[currency]);
          }
          requestData.append("min_bet", JSON.stringify(provider.min_bet) || {});
          }
        }
      if (this.selectedAllowedCurrencies) {
        if (this.isAllAllowedCurrencies) {
          const allArray = ["ALL"];
          requestData.append("allowed_currencies", JSON.stringify(allArray));
        } else{
          const allowedCurrencies = Object.keys(this.selectedAllowedCurrencies)
          if (allowedCurrencies.length) {
          requestData.append("allowed_currencies", JSON.stringify(this.selectedAllowedCurrencies) || {});
          // requestData.append("allowed_currencies", this.selectedAllowedCurrencies);
          }
        }
        }
      }
      try {
        const response = await axios.put(endpoint, requestData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response) {
          this.closeProviderDialog();
          this.$toast.add({
          severity: 'success', summary: this.$t(`provider_edit_success`), life: 4000,
        })
          this.$emit('provider-update');
        }
      } catch (error) {
        console.error('Error updating entity:', error);
        this.$toast.add({
          severity: 'error', summary: this.$t(`provider_edit_error`), life: 4000,
        })
      } finally {
        this.formLoading = false;
      }
    },
    changeProviderFile(event) {
      const file = event.files[0];

      if (file && file.size <= 15000000) {
        this.providerExceed = false;
        this.provider.logo = file;
        this.logoPreview = URL.createObjectURL(file);
      } else {
        this.providerExceed = true;
        this.logoPreview = null;
        event.target.value = null;
      }
    },
        },
    }
</script>

<style lang="scss" scoped>
.load-photo {
  align-items: center;

  background: var(--maskbg) !important;
  border: 2px dashed var(--gray-900) !important;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 5px !important;
  span {
    cursor: pointer;
  }
}
</style>