<template>
  <div class="main-item">
    <drop-list 
    :items="localProvidersOrder" 
    v-if="!loadingFlag"
    @reorder="$event.apply(localProvidersOrder)">
      <template v-slot:item="{ item, index }">
        <drag @dragend="draggbleChanged" handle=".main-item__drag"
         :data="item" class="main-item__game _provider" 
        :class="{ '_active': item.id === selectedProvider.id, 
        '_no-active': !item.is_enabled }"
        @click="select(item)" 
        :key="item.id">
        <div class='main-item__column'>
          <div class="main-item__drag _cursor-grab">
            <v-icon>mdi-menu</v-icon>
          </div>
          <div class="main-item__image" v-if="item.logo">
            <span>{{ index + 1 }}</span>
            <v-lazy v-model="isActive">
              <v-img max-height="50px" max-width="50px" 
              @load="isActive = true" 
              :src="item.logo" 
              :alt="item.name" />
            </v-lazy>
          </div>
          <button type="button" v-if="item.aggregator" 
                @click.stop="$router.push('/game-fee-settings/aggregators')"
                class="custom-badge _main"
                >
                  <span>{{ item.aggregator }} </span>
                </button>
        </div>
        <div class='main-item__column main-item__column_main'>

          <div class="main-item__name">
            <span v-if="isAgent">{{ item ? `${item.name}` : '' }} </span>
            <span v-else>{{ item ? `${item.name} (${item.total_games} ${$t('ga')}) ` : '' }} </span>
            
          </div>
          </div>
          <div class='main-item__column main-item__column_actions'>

         
          <div class="main-item__actions space-x-1">
            <span class="lh-1 white-space custom-badge _main" v-if="!item.allowed_to_agents && isAgent"> ({{ $t('not_active') }})</span>
            <template v-if="!isAgent">
              <div class="d-flex flex-column align-end space-y-1">
                <span class="lh-1 white-space custom-badge _main" v-if="!item.is_enabled"> {{ $t('not_active') }}</span>
                <span class="lh-1 white-space custom-badge _red" v-if="!item.allowed_to_agents"> {{ $t('not_active_agents') }}</span>
              </div>
                
            </template>
            
            <Button @click.stop="holdProvider(item)" icon="pi pi-pencil" class="p-button-sm p-button-warning px-8" />
          </div>
        </div>
      </drag>
    </template>
  </drop-list>
   
  <item-loading v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Drag, DropList } from "vue-easy-dnd";
    export default {
        name: 'ProviderItem',
        components:{
        Drag,
        DropList,
    },
    props:{
      selectedProvider:{
        type: Object,
      },
      providers: {
        type: Array,
        required: true
      },
      loadingFlag: {
        type: Boolean,
        required: true
      },
      triggerReset: {
        type: Boolean,
        required: true
      },
      triggerSave: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        isActive: false,
        localProvidersOrder:[],
      }
    },
    computed:{
      ...mapGetters({
        tokenUser: 'tokenUser',
      }),
      isAgent() {
        return this.tokenUser === 'agent';
      },
      // foundProviderObject() {
      //   return this.providers.find((item) => item.id === this.selectedProvider);
      // },
    },
    watch:{
      providers:{
        handler(newValue){
          if(newValue){
            this.localProvidersOrder = [...newValue];
          }
        },
        deep: true,
        immediate: true,
      },
      triggerReset(newVal) {
      if (newVal) {
        this.reset();
      }
    },
    triggerSave(newVal) {
      if (newVal) {
        this.sendData();
      }
    },
    },
    methods:{
      select(provider){
        this.$emit('select-provider', provider)
      },
      reset(){
        this.localProvidersOrder = [...this.providers];
      },
      sendData(){
        this.$emit('save-items', this.localProvidersOrder);
      },
      holdProvider(provider){
        this.$emit('open-dialog', provider);
      },
    draggbleChanged() {
      this.$emit('draggble-changed');
      this.$root.$emit('setBlock');
    },
    },
    }
</script>

<style lang="scss" scoped>
.drop-list{
  row-gap: 2px;
  display: flex;
  flex-direction: column;
}
.main-item {
  overflow: auto;
  max-height: 575px;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  position: relative;
  height: 100%;
  padding-top: 3px;
  &__game {
    padding: 3px 8px;
    display: flex;
    align-items: center;
    border: 1px solid var(--surface-500);
    border-radius: 4px;
    background-color: var(--surface-200);
    //&:nth-of-type(odd) {
    //  background-color: var(--surface-300);
    //}
//
    //&:nth-of-type(even) {
    //  background-color: var(--surface-100);
    //}

    &._active {
      border: 1px solid var(--pink-700);
      background-color: var(--pink-200) !important;
    }
    &._no-active {
      border: 1px solid var(--gray-700);
      //background-color: var(--gray-400) !important;
    }
  }
  &__column{
    display: flex;
    align-items: center;
    flex: 0 1 33.333%;
    &_actions{
      justify-content: flex-end;
    }
    &_main{
      //flex: 1 1 100%;
    }
  }
  &__drag {
    margin-right: 5px;
    i{
      color: var(--text-color-secondary);
      ._active &{
        color: var(--bluegray-900);
      } 
    }
  }
  
  &__image {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    position: relative;
    margin-right: 4px;
    line-height: 1;
      padding: 0 2px;
      background-color: var(--main-flamingo);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 16px;

    ._no-active & {
      filter: grayscale(1);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--red);
      color: var(--white);
      min-width: 20px;
      height: 20px;
      position: absolute;
      padding: 0 3px;
      border-radius: 50px;
      right: -5px;
      top: -5px;
      z-index: 2;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
        border-radius: 0px;
    }
  }
  
  &__name {
    flex: 1 1 100%;
    margin: 0 8px;
    color: var(--text-color);
    text-transform: capitalize;
      display: flex;
      justify-content: center;
      align-items: center;
    ._active &{
      color: var(--bluegray-900);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__edit {}
}
</style>