<template>
  <v-dialog v-model="dialog" :persistent="formLoading" content-class="br-16" scrollable max-width="500px">
    <v-card>
      <v-card-title>
        <h3>{{ $t('edit_game') }}</h3>
      </v-card-title>
      <v-card-text class="pb-0 py-2">
        <!-- Change Name -->
        <v-text-field 
        :disabled="isAgent"
        outlined dense color="var(--main-flamingo)" hide-details class="mb-3" v-model="game.name"
          :label="this.$t('game_name')"></v-text-field>
        <!-- Description -->
        <v-text-field 
        :disabled="isAgent"
        outlined dense color="var(--main-flamingo)" hide-details class="mb-3"
          v-model="game.description" :label="this.$t('description')"></v-text-field>
        <!-- Game Provider -->
        <v-text-field 
        :disabled="isAgent"
        v-model="game.prov_name" readonly class="mb-3" :label="$i18n.t('provider')" outlined dense
          hide-details color="var(--main-flamingo)"></v-text-field>
        <!-- Change Type -->
        <v-text-field 
        :disabled="isAgent"
        v-model="game.game_type" class="mb-3" :label="$i18n.t('game_type')" outlined dense
          hide-details color="var(--main-flamingo)"></v-text-field>
        <!-- Change Sub (Category) -->
        <!-- v-on:keyup.enter="saveNewType" @click:append="saveNewType" v-model="newGameType" -->
        <v-autocomplete 
        v-if="!isAgent"
        multiple append-icon="mdi-plus-circle-outline" :no-data-text="this.$t('game_category_empty')"
          :items="['top', 'new']" :menu-props="{
            contentClass: 'text-capitalize main-list'
          }" class="mb-3" outlined dense color="var(--main-flamingo)" hide-details v-model="game.sub"
          :label="this.$t('game_category')">

        </v-autocomplete>
        <v-autocomplete 
        v-if="!isAgent"
        multiple :no-data-text="this.$t('game_blocks_empty')" :items="gameBlocks" :menu-props="{
          contentClass: 'text-capitalize main-list'
        }" item-text="block_name" item-value="block_name" class="mb-3" outlined dense color="var(--main-flamingo)"
          hide-details v-model="game.blocks" :label="this.$t('game_blocks')">

        </v-autocomplete>
        <!-- Change Type -->
        <!-- <v-text-field outlined dense color="var(--main-flamingo)" hide-details class="mb-3"
            v-model="game.game_type" :label="this.$t('game_type')"></v-text-field> -->
        <!-- Change RTP -->
        <!-- v-model="game.rtp" -->
        <v-text-field 
        :disabled="isAgent"
        outlined dense v-model="game.fee_percentage" color="var(--main-flamingo)" hide-details
          class="mb-3" prefix="%" label="Fee"></v-text-field>
          <!-- Wagering coefficient -->
        <v-text-field 
        :disabled="isAgent"
        outlined dense v-model="game.wagering_coefficient" color="var(--main-flamingo)" hide-details
          class="mb-3" :label="$t('wagering_coefficient')"></v-text-field>
        <!-- Change min / max bet -->
          <div class="bonus-block__header" v-if="!isAgent">
            <v-checkbox color="var(--main-flamingo)" class="mt-0 mb-2" hide-details 
        v-model="isAllCurrencies"
    :label="$t('select_all_currencies')"></v-checkbox>
        <v-select
        hide-details="auto"
          :rules="[v => selectedCurrencies.length > 0 || $t('required_field')]"
          multiple
          class="auto"
          :disabled="isAllCurrencies"
          :menu-props="{contentClass: 'main-list'}"
          v-model="selectedCurrencies"
          :items="filteredCurrencies"
          :label="$t('currency')"
          outlined
          dense
          color="var(--main-flamingo)"
          >
        </v-select>
      </div>
      <div class="d-flex align-center flex-wrap">
        <template v-for="(limits, currency) in game.min_bet">
          <v-text-field hide-details="auto" 
          :key="currency" 
          :disabled="isAgent"
          v-if="selectedCurrencies.includes(currency)"
              v-model="game.min_bet[currency]" 
              outlined
              :rules="[v => v > 0 || $t('bet_greater_than_zero')]" 
              required 
              dense 
              class="auto"
              color="var(--main-flamingo)"
              :label="$t('bet')" 
              :suffix="currency">
            </v-text-field>
      </template>
        </div>
        <!-- Change Producer -->
        <!-- <v-text-field outlined dense color="var(--main-flamingo)" hide-details class="mb-3"
            v-model="game.producer" :label="this.$t('game_producer')"></v-text-field> -->

        <!-- Change Photo -->
        <FileUpload v-if="!game.logo && !isAgent" name="game-logo[]" :multiple="false" :customUpload="true" @uploader="changeGameFile" accept="image/*" :maxFileSize="1000000">
          <template #empty>
            <span>{{ $t('game_logo_select')
            }}</span>
            <p>{{ $t('drag_logo')
            }}</p>
          </template>
      </FileUpload>
        <v-col style="font-size: 12px;" class="load-photo" v-if="game.logo">
          <!-- <span style="max-width: 160px;" v-if="!game.logo" @click="triggerInput(game)"> {{ $t('game_logo_select')
          }}
          </span> -->
          <div class="d-flex flex-column align-center justify-center">
            <img :src="!logoPreview ? game.logo : logoPreview"
              style="object-fit: contain;width:220px;height:220px">
            <!-- Delete Photo -->
            <v-btn v-if="!isAgent" color="var(--red)" max-width="10" max-height="30" class="mt-1" @click="deleteImage">
              <v-icon class="icon__delete-t" style="color: var(--white);">close</v-icon>
            </v-btn>
          </div>
        </v-col>
        <!-- Limit Photo Exceed -->
        <v-row class="mt-1" v-if="gameExceed" style="color: var(--red)">
          <v-col>
            {{ $i18n.t('game_logo_exceed') }}
          </v-col>
        </v-row>
        <div class="d-flex flex-column space-y-1 mt-3">
        <!-- Allowed to agents -->
          <span class="form-switch"> 
                          <h4 class="c-text white-space title-input">
                            {{ isAgent ? $t('active') : $t('allowed_to_agents') }}: 
                          </h4>
                          <div class="wrapper-input d-flex">
                            <InputSwitch class="p-inputswitch-success" v-model="game.allowed_to_agents" />
                          </div>
                      </span>
                       <!-- Active -->
          <span v-if='!isAgent' class="form-switch"> 
                          <h4 class="c-text white-space title-input">
                            {{ $t('active') }}:
                          </h4>
                          <div class="wrapper-input d-flex">
                            <InputSwitch :disabled="isAgent" class="p-inputswitch-success" v-model="game.active" />
                          </div>
                      </span>
                       <!-- Wagerable -->
          <span v-if='!isAgent' class="form-switch"> 
                          <h4 class="c-text white-space title-input">
                            {{ $t('is_wagerable') }}:
                          </h4>
                          <div class="wrapper-input d-flex">
                            <InputSwitch :disabled="isAgent" class="p-inputswitch-success" v-model="game.is_wagerable" />
                          </div>
                      </span>
        </div>
      </v-card-text>
      <!-- Close and Save -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button class="p-button-sm p-button-danger mr-1" @click="closeGameDialog" 
              :loading="formLoading"
              :disabled="formLoading" :label="$t('close')"></Button>
              <Button class="p-button-sm p-button-success" @click="updateManager(game)" 
              :loading="formLoading"
              :disabled="game.logo === null || formLoading" :label="$t('save')"></Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
  export default {
    name: "EditGame",
    props:{
          gameDialog: Boolean,
          game: {
            type: Object,
            required: true,
            default: () => ({
              min_bet: {}
            })
          },
        },
        data() {
          return {
            gameExceed: false,
            logoPreview: null,
            formLoading: false,
            dialog: false,
            isAllCurrencies: false,
            selectedCurrencies: [],
          }
        },
        computed:{
          ...mapGetters({
            gameBlocks: 'gameBlocks/getGameBlocks',
            filteredCurrencies: 'getFilteredCurrencies',
            tokenUser: 'tokenUser',
          }),
          isAgent() {
            return this.tokenUser === 'agent';
          }
        },
        watch:{
          selectedCurrencies(newCurrencies) {
      if (!this.game.min_bet) {
        this.$set(this.game, 'min_bet', {});
      }
      for (const currency in this.game.min_bet) {
        if (!newCurrencies.includes(currency)) {
          delete this.game.min_bet[currency];
        }
      }
      newCurrencies.forEach(currency => {
        if (!this.game.min_bet[currency]) {
          this.$set(this.game.min_bet, currency, '');
        }
      });
    },
  isAllCurrencies(newValue) {
            if (newValue) {
                // add currencies
                const currencies = Object.keys(this.filteredCurrencies).map(currency => {
                    return this.filteredCurrencies[currency]; 
                });
                this.selectedCurrencies = currencies;
            } else {
                // to delete from selectedCurrencies
                this.selectedCurrencies = [];
            }
        },
    game: {
    handler(newBonus, oldBonus) {
      // editBonus
      // selectedCurrencies
      if (this.game && this.game.min_bet) {
      
      var keys = Object.keys(newBonus.min_bet);
      if (keys.length === 0) {
        return
      }
      this.selectedCurrencies = [];
      Object.keys(newBonus.min_bet).forEach(currency => {
          this.selectedCurrencies.push(currency);
        });
      }
    },
    deep: true 
  },
          gameDialog: {
            immediate: true,
            handler(newValue) {
              if (newValue) {
          this.dialog = newValue;        
              }
            }
          },
          dialog: {
            handler(newValue) {
              if (newValue === false) {
                this.$emit('game-close');
                this.deleteImage();
              }
            }
          },
        },
      methods:{
        changeGameFile(event) {
      const file = event.files[0];
      if (file && file.size <= 15000000) {
        this.gameExceed = false;
        this.game.logo = file;
        this.logoPreview = URL.createObjectURL(file);
      } else {

        this.gameExceed = true;
        this.logoPreview = null;
        event.files[0] = null;
      }
    },
        deleteImage() {
      URL.revokeObjectURL(this.logoPreview);
      this.game.logo = null;
      this.logoPreview = null;
    },
        closeGameDialog(){
          this.$emit('game-close');
          this.dialog = false;
        },
        async updateManager(game) {
      this.formLoading = true;
      let endpoint;
      let requestData
        this.isAgent ? endpoint = `/main-api/games/by_agent/${game.id}` :
        endpoint = `/main-api/games/${game.id}`;
        requestData = new FormData();
        requestData.append("order", game.order || 0);
        requestData.append("allowed_to_agents", game.allowed_to_agents || false);
        if (!this.isAgent) {
          
        
        requestData.append("active", game.active || false);
        requestData.append("is_wagerable", game.is_wagerable || false);
        requestData.append("game_type", game.game_type || "");
        if (game.description) {
          requestData.append("description", game.description || "");
        }
        requestData.append("fee_percentage", game.fee_percentage || 0);
        requestData.append("wagering_coefficient", game.wagering_coefficient || 1);
        requestData.append("name", game.name || "");
        // requestData.append("producer", game.producer || "");
        if (game.min_bet) {
          const minBet = Object.keys(game.min_bet)
          if (minBet.length) {
            for (const currency in game.min_bet) {
              game.min_bet[currency] = Number(game.min_bet[currency]);
          }
          requestData.append("min_bet", JSON.stringify(game.min_bet) || {});
          }
        }
        if (game.sub && game.sub !== null && game.sub.length > 0) {
          requestData.append("sub", JSON.stringify(game.sub));
        }
        if (game.blocks && game.blocks.length > 0) {
          requestData.append("blocks", JSON.stringify(game.blocks || []));
        }
      if (game.logo !== null && typeof game.logo !== 'string') {
        requestData.append("logo", game.logo);
      } else if (game.logo.includes("https")) {
        requestData.append("logo", game.logo);
      }
    }
      // console.log('requestData', requestData);
      try {
        const response = await axios.put(endpoint, requestData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response) {
          this.closeGameDialog()
          this.$toast.add({
          severity: 'success', summary: this.$t(`game_edit_success`), life: 4000,
        })
        this.$emit('game-update');
        }
      } catch (error) {
        console.error('Error updating entity:', error);
        this.$toast.add({
          severity: 'error', summary: this.$t(`game_edit_error`), life: 4000,
        })
      } finally {
        this.formLoading = false;
      }
    },
      },
      }
      </script>

<style lang="scss" scoped>
.load-photo {
  align-items: center;
  background: var(--surface-300) !important;
  border: 2px dashed var(--surface-500) !important;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 5px !important;

  span {
    cursor: pointer;
  }
}
</style>