<template>
      <div>
        <Card class="card-custom">
          <template #title>
            <div class="d-flex justify-end">
              <div class="column-three"></div>
              <h4 class="column-three">{{ $i18n.t('search_game') }}</h4>
              <div class="column-three d-flex justify-end">
                <span class="p-buttonset">
                  <Button @click="resetLocalOrders" :loading="loadingLayout" :disabled="!hasDraggbleChanges" :label="$t('reset')" icon="pi pi-refresh" class="p-button-sm p-button-danger" />
                  <Button @click="userConfirmChanges = true" :loading="loadingLayout" :disabled="!hasDraggbleChanges" :label="$t('save_layout')" icon="pi pi-save" class="p-button-sm p-button-success" />
              </span>
              </div>
            </div>
            
        </template>
        <template #content>
              <v-row>
                <v-col class="filters-column">
                    <InputText type="text" @input="debounceFilters" v-model="filters.name" style="max-width: 170px" class="p-inputtext-sm" :placeholder="$t('game_name')" />
                  <Dropdown  v-model="filters.provider_id" @change="changeCurrentProvider" :options="provider"
                  optionLabel="name"
                  optionValue="id"
                  dataKey="id"
                  class="custom-dropdown"
                        :placeholder="$t('provider')" :filter="true" :emptyFilterMessage="$t('no_data')">
                        <template #option="slotProps">
                          <div class="provider-complete">
                            <div class="provider-complete__logo">
                                <img :src="slotProps.option.logo" />
                            </div>
                            <div class="provider-complete__name">
                                <div>{{slotProps.option.name}} ({{ slotProps.option.aggregator }})</div>
                            </div>
                          </div>
                      </template>
                      </Dropdown>
                  <Dropdown v-if="!isAgent"  v-model="filters.aggregator_id" @change="applyFilters" :options="aggregators"
                  optionLabel="name"
                  optionValue="id"
                  class="custom-dropdown"
                        :placeholder="$t('aggregator')" :filter="true" :emptyFilterMessage="$t('no_data')">
                      </Dropdown>
                  <span class="p-buttonset">
                    <Button @click="clearFilters" :loading="loadingFlag" :disabled="loadingFlag" :label="$t('delete')" icon="pi pi-trash" class="p-button-sm p-button-danger" />
                    <Button @click="applyFilters" :loading="loadingFlag" :disabled="loadingFlag" :label="$t('search')" icon="pi pi-search" class="p-button-sm p-button-success" />
                </span>
              </v-col>
              </v-row>
                  <v-chip-group>
                    <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" @click="removeFilter(key)">
                      <template>
                      {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                      </template>
                      <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                    </v-chip>
                  </v-chip-group>
      </template>
    </Card>
        <v-row class="layout__row ma-0">
          <v-col class="mt-1 layout__col">
            <div class="my-1" style="color: var(--text-color)">
              <h3 class="text-uppercase mb-n2"> {{ $t('games') }} </h3>
              <span style="font-size:14px">({{ $t('draggble_hint') }})</span>
            </div>
            <h2 v-if="!cachedGames && !loadingPage" class="text-uppercase my-1 c-red"> 
              {{ $t('gameNotFound') }}
            </h2>
            <template v-if="cachedGames && !loadingPage">
              <game-item 
              v-if="cachedGames" 
              :class="{ '_loading': loadingFlag }"
              :games="cachedGames.items"
              @open-dialog="openGameDialog"
              @draggble-changed="setDraggbleChanged"
              :loadingFlag="loadingGame"
              :triggerReset="triggerReset"
              @select-provider="selectProvider"
              :triggerSave="triggerSave"
              @save-items="saveGamesOrder"
              > 
              <template>
                <div v-if="cachedGames.items && cachedGames.page !== cachedGames.pages">
                  <Button @click="oneMorePageGames" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('more')"
                    icon="pi pi-save" class="p-button-sm p-button-success" />
                  <div v-intersect="showMoreGamesIntersect"></div>
              </div>
              </template>
            </game-item>
            </template>
            <ProgressBar v-if="loadingPage" mode="indeterminate" style="height: .5em" />
          </v-col>
          <div class="line"></div>
          <v-col class="mt-1 layout__col">
            <div class="my-1" style="color: var(--text-color)">
              <h3 class="text-uppercase mb-n2"> {{ $t('game_studios') }} </h3>
              <span style="font-size:14px">({{ $t('draggble_hint') }})</span>
            </div>
            <h2 v-if="provider.length === 0 && !loadingFlag && !loadingPage" class="text-uppercase my-1 c-red"> 
              {{ $t('game_studios_not_found')
            }} </h2>
            <template v-if="provider && !loadingPage">
              <span class='d-flex align-center justify-center mb-1 space-x-1' v-if="!isAgent">
                <div class="">
                  <span class="p-inputgroup">
                    <Button 
                    :disabled="loadingFlag" 
                    :loading="loadingFlag" 
                    v-if="filtersProvider.name" 
                    @click="clearProvider('name')" 
                    icon="pi pi-times" class="p-button-sm p-button-danger ml-1" />
                    <Dropdown v-model="filtersProvider.name"
                    class="custom-dropdown"
                    :filter="true"
                    :disabled="loadingFlag" 
                      :loading="loadingFlag" 
                      optionLabel="name" 
                      optionValue="string_id" 
                      dataKey="id"
                      :placeholder="$t('provider')" 
                      :options="availableProviders" 
                      :emptyFilterMessage="$t('provider_not_found')">
                    
                    
                      <template #option="slotProps">
                        <div class="provider-complete">
                          <div class="provider-complete__logo">
                              <img :src="slotProps.option.logo" />
                          </div>
                          <div class="provider-complete__name">
                              <div>{{slotProps.option.name}} ({{ slotProps.option.aggregator }})</div>
                          </div>
                        </div>
                    </template>
                    </Dropdown>
                  </span>
                </div>
                <div class="">
                <div class="p-inputgroup">
                  <Button 
                  :disabled="loadingFlag" 
                  :loading="loadingFlag" 
                  v-if="filtersProvider.aggregator_string_id" 
                  @click="clearProvider('aggregator_string_id')" 
                  icon="pi pi-times" class="p-button-sm p-button-danger ml-1" />
                  <Dropdown 
                  v-model="filtersProvider.aggregator_string_id" 
                  :disabled="loadingFlag" 
                  :loading="loadingFlag" 
                  :options="aggregators"
                  optionLabel="name"
                  optionValue="name"
                  class="custom-dropdown"
                        :placeholder="$t('aggregator')" 
                        :filter="true" 
                        :emptyFilterMessage="$t('no_data')">
                  </Dropdown>
                </div>
              </div>
                  
              </span>
              <provider-item 
              @open-dialog="openProviderDialog"
              :providers="filteredProviders"
              @draggble-changed="setDraggbleChanged"
                 :loadingFlag="loadingProvider"
                 :triggerReset="triggerReset"
                 :selectedProvider="currentProvider"
                 @select-provider="selectProvider"
                 :triggerSave="triggerSave"
                 @save-items="saveProviderOrder"
                 />
            </template>
            <ProgressBar v-else mode="indeterminate" style="height: .5em" />
          </v-col>
          <!-- Dialog to Edit provider -->
          <edit-provider 
          @provider-close="providerDialogClose"
          @provider-update="getProviders"
          :providerDialog="providerDialog" :provider="editedProvider" />
          <!-- Dialog to Edit Game -->
          <edit-game 
          @game-close="gameDialog = false"
          @game-update="setOrdersWithFilters"
          :gameDialog="gameDialog" :game="editedGame" />
        </v-row>
        <!-- Dialog to have unsaved changes -->
    <v-dialog v-model="userHaveUnsavedChanges" content-class="br-16" scrollable persistent max-width="350px"
    transition="dialog-transition">
<v-card tile>
<v-toolbar flat dark color="var(--main-flamingo)">
<div class="logo-absolute">
  <img src="@/assets/images/ADMIN_LOGO.png" alt="">
</div>
<v-spacer></v-spacer>
<v-toolbar-title class="font-weight-bold">{{ $t('alert') }}!</v-toolbar-title>
<span class="close-popup" @click="userHaveUnsaved = false"></span>
<v-spacer></v-spacer>
</v-toolbar>

<v-card-text>
<v-col class="d-flex justify-center">
              <span class="d-flex" style="color: #161421;font-size: 17px;line-height: 1.5;">
                {{$t('unsaved_layout') }}
              </span>
</v-col>
<v-card-actions class="d-flex justify-space-between" style="gap: 10px;">
  <v-col class="pa-0 text-right">
    <v-btn @click="discardChanges" color='var(--red)!important'
    style="width:105px"
           class="main-btn">{{ $t('discard') }}
    </v-btn>
  </v-col>

  <v-col class="pa-0 text-left">
    <v-btn @click="saveLayoutAndRedirect" style="width:105px" class="bg-c-green c-white">{{ $t('save') }}</v-btn>
  </v-col>
</v-card-actions>
</v-card-text>
</v-card>
</v-dialog>
<!-- Dialog to confirm changes -->
<v-dialog v-model="userConfirmChanges" content-class="br-16" scrollable max-width="350px"
    transition="dialog-transition">
<v-card tile>
<v-toolbar flat dark color="var(--main-flamingo)">
<div class="logo-absolute">
  <img src="@/assets/images/ADMIN_LOGO.png" alt="logo">
</div>
<v-spacer></v-spacer>
<v-toolbar-title class="font-weight-bold">{{ $t('toConfrim') }}</v-toolbar-title>
<v-spacer></v-spacer>
</v-toolbar>

<v-card-text>
              <div class="d-flex c-text font-weight-bold justify-center">
                {{$t('confirm_layout') }}
              </div>
<v-card-actions>
  <span class="p-buttonset fw">
    <Button @click="userConfirmChanges = false" :label="$t('cancelLabel')" class="p-button-sm p-button-danger" />
    <Button @click="confirmLayoutChanges" :label="$t('save')" class="p-button-sm p-button-success" />
  </span>
</v-card-actions>
</v-card-text>
</v-card>
</v-dialog>
      </div>
    
</template>
  
<script>
import {debounce} from 'lodash'
import { Drag, DropList } from "vue-easy-dnd";
import GameItem from "./GameItem.vue";
import ProviderItem from "./ProviderItem.vue";
import EditProvider from './EditProvider.vue'
import EditGame from './EditGame.vue'
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "ByProvider",
  components: {
    Drag,
    DropList,
    EditProvider,
    EditGame,
    GameItem,
    ProviderItem,
  },
  props: {
  },

  data() {
    return {
      userHaveUnsavedChanges: false,
      userConfirmChanges: false,
      gameDialog: false,
      editedGame: {},
      providerDialog: false,
      editedProvider: {},
      formLoading: false,
      providerFilters: {
        page: 1,
        size: 100,
        name: '',
      },
      isActive: false,
      loadingGame: false,
      loadingProvider: false,
      loadingFlag: false,
      loadingPage: false,
      currentProvider: {},
      startPage: 1,
      startSize: 100,
      activeFilters: {},
      activeProviderFilters: {},
      filters:{},
      filtersProvider: {
        name: '',
        aggregator_string_id: '',
      },
      isIntersectGames: false,
      hasDraggbleChanges: false,
      triggerSave: false,
      triggerReset: false,
      loadingLayout: false,
      availableProviders: [],
    }
  },
  async mounted() {
    this.loadingPage = true;
    if (!this.providerFromSettings && !this.gameFromSettings) {
      // console.log(this.provider);
      if (this.provider.length === 0) {
        try {
          await this.getProviders();
        } catch (error) {
          this.$toast.add({ severity: 'error', 
          summary: this.$t('just_error'), 
          detail: error.message, life: 3000 });
        } finally{
        }
      }
      await this.holdProviderToGame(this.provider[0]);
      await this.applyFilters();
    }
    this.loadingPage = false;
    // await this.applyProviderFilters();
  },
  created() {
    this.$root.$on('resetProviderLocalOrders', this.setLocalOrders);
    this.$root.$on('confirmLayoutChangesByProvider', this.saveLayoutOrders);
  },
  watch: {
    triggerReset(newVal) {
      if (newVal) {
        this.reset();
      }
    },
    //cachedGames(newValue, oldValue){
    // console.log('change', newValue);
    //  if (newValue) {
    //    this.setLocalOrders();
    //  }
    //},
  },
  computed: {
    ...mapGetters({
      provider: 'providers/getFilteredProviders',
      providerFromSettings: 'gameModule/providerFromSettings',
      gameFromSettings: 'gameModule/gameFromSettings',
      gameArray: 'gameModule/getGameArray',
      aggregators: 'aggregators/getAggregators',
      tokenUser: 'tokenUser',
    }),
    filteredProviders(){
      if (this.isAgent && this.provider) {
        this.availableProviders = this.provider
        return this.provider;
      }
                if (this.provider) {
                    const filterArray = this.availableProviders = this.provider
                    // console.log('filterArray', filterArray);
                    //console.log('this.filtersProvider.name', this.filtersProvider.name);
                    //console.log('this.filtersProvider.aggregator_string_id', this.filtersProvider.aggregator_string_id);
                    if (this.filtersProvider.name && this.filtersProvider.aggregator_string_id) {
                      return filterArray.filter(item => item.string_id === this.filtersProvider.name && item.aggregator === this.filtersProvider.aggregator_string_id)
                    } else if (this.filtersProvider.name) {
                        return filterArray.filter(item => item.string_id === this.filtersProvider.name)
                    } else if (this.filtersProvider.aggregator_string_id) {
                        return filterArray.filter(item => item.aggregator === this.filtersProvider.aggregator_string_id)
                    } 
                    else {
                        return filterArray
                    }
                } else{
                    return []
                }
      },
    linkToRedirect(){
      return this.$store.state.linksHref.linkHref;
    },
    isAgent() {
                return this.tokenUser === 'agent';
            },
    filteredActiveFilters() {
      // computed active filters
      return Object.fromEntries(
        Object.entries(this.activeFilters).filter(
          ([key, value]) => 
          key !== 'findGame' && key !== 'allGames' && 
          key !== 'size' && key !== 'page' && (key === 'is_active' || value !== false)
        )
      );
    },
    // cachedGames() {
    //   let filteredItem;
    //   if (this.gameArray && this.filters.provider_id && !this.filters.name) {
    //       filteredItem = Object.keys(this.gameArray).find(item => item === this.filters.provider_id);
        
    //       if (filteredItem) {
    //       return {
    //         items: this.gameArray[filteredItem].items,
    //         page: this.gameArray[filteredItem].page,
    //         pages: this.gameArray[filteredItem].pages,
    //         size: this.gameArray[filteredItem].size,
    //         total: this.gameArray[filteredItem].total,
    //       };
    //     } else {
    //       return null; 
    //     }
    //   }else if (this.gameArray && this.filters.name) {
    //         filteredItem = Object.keys(this.gameArray).find(item => item === this.filters.provider_id);
    //     const capitalizedName = this.filters.name.charAt(0).toUpperCase() + this.filters.name.slice(1).toLowerCase();
    //     const provider = this.filters.provider_id;
    //     if (this.gameArray[provider]) {
    //       const filteredGame = this.gameArray[provider].items.filter(item => item.name.includes(capitalizedName));
    //       // Проверка результата
    //       if (filteredGame.length > 0) {
    //         return {
    //         items: filteredGame,
    //         page: this.gameArray[filteredItem].page,
    //         pages: this.gameArray[filteredItem].pages,
    //         size: this.gameArray[filteredItem].size,
    //         total: this.gameArray[filteredItem].total,
    //       };
    //       } else {
    //         return null
    //       }
    //     } else {
    //       return null
    //     }
    //   } else{
    //     return null
    //   }
    // },
    cachedGames() {
      if (this.gameArray && this.gameArray.items && this.gameArray.items.length !== 0) {
      return this.gameArray;
      } else{
        return null
      }
    },
  },
  methods: {
    debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
    clearProvider(filter){
      this.filtersProvider[filter] = null;
    },
    discardChanges(){
        this.triggerReset = true;
       this.userHaveUnsaved = false;
       this.hasDraggbleChanges = false;
       this.$toast.add({ severity: 'info', summary: this.$t('discard_layout'), life: 4000 })
       this.redirectAfterLayoutPopup();
     },
     confirmLayoutChanges(){
      this.loadingFlag = true;
      this.triggerSave = true;
      this.loadingFlag = false;
      this.hasDraggbleChanges = false;
      this.userConfirmChanges = false;
    },
     async saveLayoutAndRedirect(){
       this.confirmLayoutChanges();
       this.redirectAfterLayoutPopup();
     },
     redirectAfterLayoutPopup(){
      this.$root.$emit('layoutOpenMenu');
      if (this.$route.path === '/game-manager/providers') {
        this.$router.push(this.linkToRedirect);
      }
      this.$store.commit('linksHref/setLinkHref', '');
    },
    providerDialogClose(){
      this.providerDialog = false;
      this.editedProvider = {};
    },
    async resetLocalOrders(){
      this.triggerReset = true;
      this.hasDraggbleChanges = false;
    },
    reset(){
      this.$nextTick(() => this.triggerReset = false);
    },
    setDraggbleChanged(){
      this.hasDraggbleChanges = true;
    },
    async saveGamesOrder(games) {
      // console.log(games);  
      const { items, first } = games;
      const editedGameOrder = items.map((item, index) => ({
        id: item.id,
        order: first + index * 100, 
      }));
      let end;
      this.isAgent ? end = `/main-api/games/by_agent` :
      end = `/main-api/games`;
      this.saveLayoutOrders(end, editedGameOrder);
    },
    async saveProviderOrder(providers) {
      // console.log(providers);  
      const editedProviderOrder = providers.map((item, index) => ({
        id: item.id,
        order: index * 100, 
      }));
      let end;
      this.isAgent ? end = `/api/providers/by_agent` :
      end = `/api/providers`;
      this.saveLayoutOrders(end, editedProviderOrder);
    },
    async saveLayoutOrders(endpoint, data){

      const res = await axios.put(endpoint, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      if (res) {
        this.$root.$emit('setUnblock');
        //this.triggerSave = true;
        this.$toast.add({
          severity: 'success', summary: this.$t("saved_layout_success"), life: 4000,
        })
        this.triggerSave = false;
      } else {
        this.$toast.add({
          severity: 'error', summary: this.$t("saved_layout_error"), life: 4000,
        })
      }
      this.disabledAllFlags();

    },
    openGameDialog(game) {
      this.editedGame = { ...game };
      this.gameDialog = true;
    },
    openProviderDialog(provider) {
      this.editedProvider = JSON.parse(JSON.stringify(provider));
      this.providerDialog = true;
    },
    //========================================================================================================================================================
    async holdProviderToGame(provider) {
      this.filters.provider_id = provider.id;
      this.currentProvider = provider;
      this.$store.commit('gameModule/SET_PROVIDER_FILTER', provider.id);
      this.$root.$emit('selectProvider');
    },
    async selectProvider(provider) {
      if (this.filters.provider_id === provider.id) {
        return;
      }
      this.loadingGame = true;
      this.filters.page = this.startPage;
      this.filters.size = this.startSize;
      this.holdProviderToGame(provider);
      await this.setOrdersWithFilters();
    },
    changeCurrentProvider(provider) {
      const providerObj = this.provider.find(item => item.id === provider.value);
      this.currentProvider = providerObj;
      this.applyFilters();
    },
    async applyFilters() {
      this.filters.page = this.startPage;
      this.filters.size = this.startSize;
      this.setOrdersWithFilters(); 
    },
    showMoreGamesIntersect(entries, observer) {
      // check instersect block
      if (entries[0].isIntersecting && this.isIntersectGames) {
        // call
        this.loadingFlag = true;
        this.oneMorePageGames();
      }
    },
    oneMorePageGames(){
      this.isIntersectGames = true;
      this.filters.page += this.startPage;
      this.filters.size = this.startSize;
      this.setOrdersWithFilters('one_more');
    },
    async setOrdersWithFilters(condition){
      this.loadingFlag = true;
      //if (condition !== 'one_more') {
        // if (Object.keys(this.gameArray).find(item => item === this.filters.provider_id)) {
        // }
        // this.disabledAllFlags();
        // return;
      //}

      this.setFilters();
      await this.$store.dispatch('gameModule/getFromGameModule', {filters: this.filters, agent: this.isAgent});
      this.disabledAllFlags();
    },
    disabledAllFlags() {
      this.loadingGame = false;
      this.loadingFlag = false;
      this.loadingProvider = false;
    },
    setFilters() {
      const filters = {};
      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key, value);
        }
      }
      // this.$store.commit('gameModule/SET_FILTERS', filters);
    },
    async setLocalOrders() {
      // if (this.cachedGames && this.cachedGames.items) {
      //   this.localGamesOrder = [...this.cachedGames.items];
      // }
      // if (this.provider) {
      //   this.localProviderOrder = [...this.provider].filter(item => item.value !== "" && item.value !== 'All Games');
      // } 
    },
    async getProviders() {
        try {
          await this.$store.dispatch('providers/awaitGameProviders', {filters: this.providerFilters, type: this.isAgent});
        } catch (error) {
          this.$toast.add({severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 3000})
        }
      
      this.disabledAllFlags();
    },
    async removeFilter(key) {
      this.loadingFlag = true;
      this.$delete(this.activeFilters, key);
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      this.filters.size = this.startSize;
      this.filters.page = this.startPage;
      await this.setOrdersWithFilters();
    },
    async clearFilters() {
      this.loadingFlag = true;
      Object.keys(this.filters).forEach((key, index) => {
        this.filters[key] = ''
      })
      Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
      })
      // this.filters.provider_id = this.provider[1].name;
      this.filters.size = this.startSize;
      this.filters.page = this.startPage;
      await this.setOrdersWithFilters();
    }, 
    getFilterValueTitle(key) {
      // title chips
      switch (key) {
       
        case 'name':
          return this.$t('game_name')
        case 'provider_id':
          return this.$t('provider')
        case 'aggregator_id':
          return this.$t('aggregator')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // text chips
      switch (key) {

        case 'provider_id':
        const provider = this.provider.find(el => el.id === value);
          return provider.name

        case 'aggregator_id':
          const aggregator = this.aggregators.find(el => el.id === value);
          return aggregator.name;
        default:
          return value
      }
    },
    //async applyProviderFilters() {
      //if (!this.loadingFlag) {
      //  this.loadingFlag = true;
      //}
      // this.providerArray = 
      //await this.$store.dispatch('getFilteredGamesProviders', this.providerFilters);

      // for (const [key, value] of Object.entries(this.providerFilters)) {
      //   if (value !== '') {
      //     this.$set(this.activeProviderFilters, key, value);
      //   } else {
      //     this.$delete(this.activeProviderFilters, key, value);
      //   }
      // }
     
    //},
    //========================================================================================================================================================
  },

}
</script>
  
<style lang="scss" scoped>




.layout {

  @media (max-width:850px) {
    min-height: 100%;
  }

  &__row {
    @media (max-width:850px) {
      display: block;
    }
  }

  &__col {
    
  }
}

.line {
  width: 1px;
  border: 2px dashed var(--surface-400) !important;

  @media (max-width:850px) {
    display: none;
  }
}

.custom-overlay {
  background-color: rgb(255 255 255 / 0.3) !important;
  backdrop-filter: blur(10px);
}

.logo-absolute {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 5px;
  top: 5px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.close-popup {
  display: block;
  position: absolute;
  right: 25px;
  top: 30px;
  width: 25px;
  height: 18px;
  z-index: 4;
  cursor: pointer;

  span,
  &::before,
  &::after {
    content: "";
    transition: all 0.3s ease 0s;
    right: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--white);
  }

  &::before {
    top: calc(50% - toRem(1));
    transform: rotate(-45deg);
  }

  &::after {
    bottom: calc(50% - toRem(1));
    transform: rotate(45deg);
  }

  span {
    width: 0;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.main-item {
  overflow: auto;
  max-height: 575px;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  position: relative;
  height: 100%;
  padding-top: 3px;
  &__game {
    padding: 3px 8px;
    display: flex;
    align-items: center;
    border: 1px solid var(--surface-border);

    &:nth-of-type(odd) {
      background-color: var(--surface-100);
    }

    &:nth-of-type(even) {
      background-color: var(--surface-300);
    }

    &._provider  {
      &:nth-of-type(even) {
        background-color: var(--surface-100);
      }

      &:nth-of-type(odd) {
        background-color:  var(--surface-300);
      }
    }

    &._active {
      border: 1px solid var(--pink-700);
      background-color: var(--pink-200) !important;
    }
  }

  &__drag {
    margin-right: 5px;
    i{
      color: var(--text-color-secondary);
      ._active &{
        color: var(--bluegray-900);
      } 
    }
  }
  
  &__image {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    position: relative;
    margin-right: 4px;
    display: flex;
    
    ._provider & {
      padding: 0 2px;
      background-color: var(--main-flamingo);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 16px;
    }

    ._no-active & {
      filter: grayscale(1);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--red);
      color: var(--white);
      min-width: 20px;
      height: 20px;
      position: absolute;
      padding: 0 3px;
      border-radius: 50px;
      right: -5px;
      top: -5px;
      z-index: 2;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      border-radius: 16px;
      ._provider & {
        border-radius: 0px;
      }
    }
  }
  
  &__name {
    flex: 1 1 100%;
    margin: 0 8px;
    color: var(--text-color);
    text-transform: capitalize;
    ._provider & {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ._active &{
      color: var(--bluegray-900);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__edit {}
}



</style>
  